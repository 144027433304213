.App {
    font-family: Helvetica, Verdana, Georgia, sans-serif;
    background-color: #ffffff;
    display: flex;
    flex-flow: column;
    width: 100%;

    * {
        box-sizing: border-box;
    }
}
.metadata-container {
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.logo-heading-container {
    display: flex;
    flex-flow: column;

    .heading-description {
        margin: 0.25rem 0 0 0;
        padding: 0;
    }
}

.contact-info-container {
    color: #ffffff;
    margin-right: 2rem;

    .contact-info-list {
        display: flex;
        flex-flow: row nowrap;
        margin: 0;
        padding: 0;
        list-style-type: none;
        font-size: 0.875rem;

        .contact-info-item {
            margin: 0.15rem 1rem;
            font-size: 1.5rem;
            font-weight: 700;
            white-space: nowrap;

            .go-back-link {
                display: flex;
                flex-flow: row nowrap;
                white-space: nowrap;
                align-items: center;
                justify-content: space-around;
                padding: 0 0.25rem;
                color: #ffffff;
                transition: color 0.5s, border 0.5s;
                text-decoration: none;
                border: 0.25rem solid #ff0060;
                background-color: #ff0060;
                color: #ffffff;
                border-radius: 0.25rem;
                width: fit-content;
                    &:hover {
                        background-color: #000000;
                        border: 0.25rem solid #ffffff;
                    }
            }
        }
    }
}

.skills-container {
    background-color: transparent;
}

.introduction-tag-line {
    font-size: 1rem;
}

.introduction-text {
    display: flex;
    flex-flow: column;
    margin: 0;
    font-size: 1.25rem;
}

.introduction-tag-line {
    margin-top: 0.5rem;
}

.skills-details-text,
.skills-details-text-heading {
    padding: 1.5rem 0.5rem;
    margin: 2rem;
    display: flex;
    align-items: center;
    font-size: 4rem;
    font-weight: 700;
    color: #ffffff;
}
.about-me-text {
    padding: 0.5rem;
    margin: 0.5rem 2rem;
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffffff;
}

.code-sample-text-explanation {
    padding: 0.5rem 2.5rem;
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffffff;

}
.introsTitles {
    color: #ffffff;
    margin: 0;
    padding: 0;
    font-size: 4rem;
    font-weight: 800;
    letter-spacing: 1px,
}
.introsTitles:nth-child(3) {
    color: #ff0060;
    margin: 0;
    padding: 0;
    font-size: 6rem;
    font-weight: 800;
    letter-spacing: 1px,
}
.footerTitles {
    color: "#ffffff";
}
.footerTitles-0 {
    font-size: 2.5rem;

}
.footerTitles-1 {
    font-size: 1.5rem;
}

.accessibility-page-container {
    & .examples-context-text {
        padding: 0.5rem 2.5rem;
        margin: 0;
        display: flex;
        font-size: 1.5rem;
        font-weight: 500;
        color: #ffffff;
    }
}

.go-home-link {
    font-size: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    padding: 0 0.25rem;
    transition: color 0.5s, border 0.5s;
    text-decoration: none;
    border: 0.25rem solid #ff0060;
    background-color: #ff0060;
    color: #ffffff;
    text-align: right;
    border-radius: 0.25rem;
    &:hover {
        background-color: #000000;
        border: 0.25rem solid #ffffff;
    }
}

.bottom-page-link {
    flex-flow: row-reverse;
    & .go-home-link{
        margin: 0 2.5rem;
    }
}

.work-links-routing{
    transition: color 0.5s, border-bottom 0.5s;
    text-decoration: none;
    border-bottom: 0.25rem solid transparent;
    color: #ffffff;
    text-align: right;
    &:hover {
        color: #ff0060;
        border-bottom: 0.25rem solid #ff0060;
    }
}

.open-close-button {
    transition: font-size 0.5s, color 0.5s, border-bottom 0.5s;
    text-decoration: none;
    border-bottom: 0.25rem solid #ffffff;
    color: #ffffff;
    text-align: right;
    &:hover {
        color: #ff0060;
        border-bottom: 0.25rem solid #ff0060;
    }
}

.home-link {
    display: flex;
    flex-flow: row nowrap;
    transition: color 0.5s,border-bottom 0.5s;
    -webkit-text-decoration: none;
    text-decoration: none;
    border-bottom: 0.25rem solid transparent;
    color: #ffffff;
    width: fit-content;
    &:hover {
        color: #ff0060;
        border-bottom: 0.25rem solid #ff0060;
        
    }
}

// Media queries for responsive design
$tabletSize: 768px;
$largeMediumTablet: 716px;
$largeWidthPhone: 562px;
$mediumPhone: 510px;
$mediumSmallPhone: 390px;

@media (max-width: $tabletSize) {
    .introsTitles {
        font-size: 3rem;
    }
    .introsTitles:nth-child(3) {
        font-size: 4rem;
    }
    .skills-details-text-heading {
        padding: 1.5rem 0.5rem;
        font-size: 2rem;
    }
    .skills-text-container {
        background: linear-gradient(0deg, rgb(0, 0, 0) 88%, rgba(0, 0, 0, 0) 98%);
    }
    .demos-container {
        margin: 2.5rem 0;
    }
}

@media (max-width: $largeMediumTablet) {
    .accessibility-header {
        flex-flow: column;
        .contact-info-list {
            flex-flow: column;
            padding: 0.5rem 0.75rem;
        }
    }
    .vioski-app-header {
        flex-flow: column;

        .contact-info-list {
            flex-flow: column;
            padding: 1rem 0.75rem;
        }
    }
    .go-back-link {
        margin: 0.5rem 0;
    }

}

@media (max-width: $largeWidthPhone) {
    .app-header {
        flex-flow: column;
    }
    .contact-info-container {
        .contact-info-list {
            flex-flow: column;
            padding: 0.5rem 0.75rem;
        }
    } 
    .accessibility-header {
        flex-flow: column;
        .contact-info-list {
            flex-flow: column;
            padding: 0.5rem 0.75rem;
        }
    }
}

@media (max-width: $mediumPhone) {
    .introsTitles {
        font-size: 2rem;
    }
    .introsTitles:nth-child(3) {
        font-size: 3rem;
    }
    .skills-details-text-heading {
        padding: 1.5rem 0.5rem;
        font-size: 2rem;
    } 
}

@media (max-width: $mediumSmallPhone) {
    .introsTitles {
        font-size: 1.5rem;
    }
    .introsTitles:nth-child(3) {
        font-size: 2.5rem;
    }
    .skills-details-text-heading {
        padding: 1.5rem 0.5rem;
        font-size: 2rem;
    }
    .code-sample-container-grid {
        padding: 0;
        margin: 0;
    }
}


